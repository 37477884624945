import { GADataType } from '../../../../../types';
import {
  TaskLdCustomerID,
  TaskLdSku,
  TaskNanoID
} from '../../../../tasks/tasksTypes';

import { LdFileViewerContent } from './components/LdFileViewerContent';

import {
  SimpleModalButton,
  SimpleModalButtonDefaultProps
} from '../../../../../helpers/buttons/SimpleModalButton';

type LdFileViewerModalButtonProps = Pick<
  SimpleModalButtonDefaultProps,
  | 'className'
  | 'i18nText'
  | 'i18nTextClassName'
  | 'i18nTitle'
  | 'title'
  | 'icon'
  | 'iconClassName'
  | 'tooltipPlacement'
  | 'tooltipI18nText'
  | 'modalSize'
> & {
  taskLdSku: TaskLdSku;
  taskLdCustomerId: TaskLdCustomerID;
  taskNanoId: TaskNanoID;
};

function LdFileViewerModalButton({
  className,
  dataGa,
  i18nText,
  i18nTextClassName,
  i18nTitle,
  icon,
  iconClassName,
  modalSize,
  title,
  tooltipI18nText,
  tooltipPlacement,
  taskLdSku,
  taskLdCustomerId,
  taskNanoId
}: LdFileViewerModalButtonProps & GADataType) {
  return (
    <SimpleModalButton
      addModalClassName="h-full"
      buttonsContainerClassName="p-0"
      childrenClassName="w-full h-full relative"
      className={className}
      dataGa={dataGa}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nTitle={i18nTitle}
      icon={icon}
      iconClassName={iconClassName}
      modalSize={modalSize || 'full'}
      title={title}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      withoutCancelButton
      withoutSubmitButton
    >
      <LdFileViewerContent
        taskLdCustomerId={taskLdCustomerId}
        taskLdSku={taskLdSku}
        taskNanoId={taskNanoId}
      />
    </SimpleModalButton>
  );
}

export default LdFileViewerModalButton;
