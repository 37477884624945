import { gql } from 'graphql-request';

import { TaskID, TaskLdAuthToken, TaskNanoID, TaskUUID } from '../tasksTypes';

export interface FetchTaskLdAuthTokenQueryResponse {
  id: TaskID;
  uuid: TaskUUID;
  ldAuthToken: TaskLdAuthToken;
}

export const FETCH_TASK_LD_AUTH_TOKEN_QUERY = gql`
  query TaskLdAuthToken($uuid: ID!) {
    task(uuid: $uuid) {
      id
      uuid
      ldAuthToken
    }
  }
`;
