import isEmpty from 'lodash/isEmpty';

import {
  TaskLdCustomerID,
  TaskLdSku,
  TaskNanoID
} from '../../../../../../tasks/tasksTypes';
import {
  FETCH_TASK_LD_AUTH_TOKEN_QUERY,
  FetchTaskLdAuthTokenQueryResponse
} from '../../../../../../tasks/queries/fetchTaskLdAuthToken';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';
import { useTask } from '../../../../../../tasks/hooks/useTask';

import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { NoResults } from '../../../../../../../helpers/NoResults';

import { TaskCache } from '../../../../../../tasks/TaskCache';
import { LD_CONFIGURATOR_URL } from '../../../../../../../config';

interface LdFileViewerContentProps {
  taskLdCustomerId: TaskLdCustomerID;
  taskLdSku: TaskLdSku;
  taskNanoId: TaskNanoID;
}

function LdFileViewerContent({
  taskLdCustomerId,
  taskLdSku,
  taskNanoId
}: LdFileViewerContentProps) {
  const { task, taskFetched, taskError } =
    useTask<FetchTaskLdAuthTokenQueryResponse>({
      tasks: [],
      cacheKey: TaskCache.showLdCacheKey(taskNanoId),
      query: FETCH_TASK_LD_AUTH_TOKEN_QUERY,
      uuid: taskNanoId
    });

  useShowToastOnErrorChange({ error: taskError });

  const ldConfiguratorUrl = `${LD_CONFIGURATOR_URL}/by_product_id/${taskLdCustomerId}/${taskLdSku}?token=${task?.ldAuthToken}`;

  return (
    <LoadingSkeleton loaded={taskFetched}>
      {isEmpty(task?.ldAuthToken) ? (
        <NoResults />
      ) : (
        <div className="w-full h-full">
          <iframe
            allow="accelerometer; autoplay; camera;encrypted-media; gyroscope; picture-in-picture;xr-spatial-tracking;xr"
            allowFullScreen
            className="w-full h-full sm:rounded-b-3xl"
            src={ldConfiguratorUrl}
          />
        </div>
      )}
    </LoadingSkeleton>
  );
}

export default LdFileViewerContent;
